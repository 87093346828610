@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.pdp-hero{
    background: $background-icy-lake;
    padding: to-rem(32) to-rem(32) 0 to-rem(32);
    overflow: hidden;

    @include desktop{
        padding-top: to-rem(48);
    }

    @include tablet{
        padding: to-rem(48) to-rem(80) 0 to-rem(80);
    }

    &__section-container{
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-24;

        @include desktop{
            max-width: $desktop-12-columns;
        }
    }

    &__upper-panel-text{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        text-align: center;

        &--text{
            @include body-large;
        }
    }

    &__upper-panel{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-16;

        @include desktop{
            max-width: to-rem(786);
            gap: $spacing-24;
            z-index: 100;
        }
    }

    &__lower-panel{
        display: flex;
        justify-content: center;
        position: relative;

        &--image{
            width: 100%;

            @include desktop{
                width: 80%;
            }
        }

        &--image-parallel{
            display: none;

            @include desktop{
                display: block;
                position: absolute;
                bottom: 39%;
                left: 50%;
                transform: translate(-50%, 0);
                width: 80%;
                transition: 0.3s ease;
            }
        }
    }
    
}