@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.inline-tile{
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include desktop{
        flex: 1;
    }

    @include tablet{
        width: 60%;
    }

    &__image{
        width: to-rem(96);
    }

    &__text{
        @include body-medium;
    }
}