@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.ai-button{
    width: to-rem(24);
    height: to-rem(24);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-white;
    border: 1px solid $button-icon-border;
    border-radius: to-rem(4);

    &:hover{
        background-color: $netural-10;
    }

    &:active{
        background-color: $netural-20;
    }

    &__icon{
        height: to-rem(12);
    }
}