@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.landing-page{
    overflow-y: auto;

    &__tiles-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include section-spacing;
        margin: auto;
        gap: $spacing-24;
        
        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-48;
        }

        @include tablet{
            gap: $spacing-32;
        }
    }

    &__tiles-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;

        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            gap: $spacing-32;
        }

        @include tablet{
            align-items: center;
        }

        &--card{
            @include tablet{
                flex-direction: row;
            }
        }
    }

    &__cards-section{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include section-spacing;
        margin: auto;

        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-48;
        }

        @include tablet{
            align-items: center;
            gap: $spacing-32;
        }
    }

    &__card-text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            max-width: $desktop-8-columns;
            align-items: center;
            justify-content: center;
            margin: auto;
        }

        &--text{
            @include body-medium;
        }
    }

    &__image-text-section{
        display: flex;
        flex-direction: column-reverse;
        gap: $spacing-24;
        justify-content: center;
        align-items: center;
        margin: auto;
        @include section-spacing;
        gap: $spacing-24;
        
        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-104;
            flex-direction: row;
        }

        @include tablet{
            gap: $spacing-32;
            flex-direction: row;
        }
    }

    &__image-text-left-panel{
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            width: 100%;
            justify-content: center;
        }

        @include tablet{
            width: 100%;
            justify-content: center;
        }

        &--text{
            @include body-medium;
        }
    }

    &__image-text-right-panel{
        width: 100%;

        &--image{
            width: 100%;
        }
    }

    &__pricing-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include section-spacing;
        margin: auto;
        gap: $spacing-24;
        
        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-48;
        }

        @include tablet{
            gap: $spacing-32;
        }
    }

    &__pricing-heading-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;

        @include desktop{
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &--title{
            text-align: left;

            @include desktop{
                max-width: to-rem(524);
            }
        }
    }

    &__promo-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include section-spacing-end;
        margin: auto;
        gap: $spacing-24;
        
        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-48;
        }

        @include tablet{
            gap: $spacing-32;
        }
    }

    &__promo-left-panel{
        display: flex;
        flex-direction: column;
        padding: $spacing-32;
        gap: $spacing-24;
        width: 100%;
        text-align: left;

        @include desktop{
            display: block;
            padding: $spacing-64 0 $spacing-64 $spacing-64;
            gap: $spacing-24;
        }

        @include tablet{
            display: block;
        }
    }

    &__promo-left-panel-text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            padding-bottom: $spacing-24;
        }

        @include tablet{
            padding-bottom: $spacing-24;
        }

        &--text{
            @include body-large;
        }
    }

    &__promo-right-panel{
        display: none;

        @include desktop{
            display: flex;
        }

        &--image{
            width: 100%;
        }
    }

    &__promo-section-wrapper{
        background: $background-icy-lake;
        display: flex;
        flex-direction: row;
        @include border-radius;
    }

    &__pricing-section-card-wrapper {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: $spacing-16;
        scroll-snap-type: x mandatory;
        scroll-padding-left: $spacing-24;  
        width: 100%;

        &::-webkit-scrollbar {
            display: none;  
        }

        -ms-overflow-style: none;   
        scrollbar-width: none;

        & > * {
            flex: 0 0 auto;
            scroll-snap-align: start;
            min-width: 80%;

            @include desktop{
                flex: initial;
                scroll-snap-align: unset;
                min-width: auto;
            }
        }

        @include tablet {
            flex-direction: row;
            gap: $spacing-24;
        }

        @include desktop {
            justify-content: space-between;
            gap: $spacing-32;
            flex-direction: row;
            overflow-x: auto; 
            scroll-snap-type: none;  
        }
    }
}