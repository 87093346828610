@use './functions' as *;

// colours
$forest-90: #004733;

$bliss-10: #E4FCE5;
$bliss-20: #BBEABD;
$bliss-30: #A6E1A8;
$bliss-60: #3EB543;
$bliss-80: #118216;
$bliss-90: #0D6210;
$bliss-100: #08410B;

$sahara-10: #FEF6E9;

$icy-lake-20:#E4EDEC;
$icy-lake-30: #D6E3E2;
$icy-lake-40:#C8DAD8;
$icy-lake-50: #ADC8C5;
$icy-lake-60: #91B5B1;
$icy-lake-70: #76A39E;

$netural-0: #ffffff;
$netural-10: #F8F8F8;
$netural-20: #EEEFEE;
$netural-30: #D0D2D0;
$netural-40: #C1C3C1;
$netural-60: #959795;
$netural-70: #777977;
$netural-80: #595C59;
$netural-90: #474A47;
$netural-100: #353735;
$netural-120: #121212;

$golden-meadow-70: #F3A526;

$red-10: #FCE8E9;
$red-60: #E41D28;
$red-70: #B61720;
$red-80: #891118;

$blue-10: #E8F3FF;
$blue-80: #105199;

// colour token
$text-light: $sahara-10;
$text-light-w: $netural-0;
$text-light-grey: $netural-60;
$text-dark: $netural-120;
$text-dark-subtle: $netural-100;
$text-dark-subtlest: $netural-80;
$text-light-green: $bliss-60;
$text-green: $bliss-80;
$text-red: $red-60;

$text-error: $red-80;
$background-error: $red-10;
$text-info: $blue-80;
$background-info: $blue-10;
$text-default: $netural-80;
$background-default: $netural-10;
$text-success: $bliss-80;
$background-success: $bliss-10;

$button-default: $bliss-80;
$button-hover: $bliss-90;
$button-active: $bliss-100;
$button-ghost-hover: $bliss-20;
$button-ghost-active: $bliss-30;
$button-destructive-default: $red-60;
$button-destructive-hover: $red-70;
$button-destructive-active: $red-80;
$button-icon-grey-default: $netural-70;
$button-icon-grey-hover: $netural-90;
$button-icon-grey-active: $netural-100;
$button-icon-border: $netural-40;

$text-link-default: $bliss-80;
$text-link-hover: $bliss-90;
$text-link-active: $bliss-100;

$divider: #E2E2E2;
$divider-icy-lake: $icy-lake-30;

$background-forest: $forest-90;
$background-grey: $netural-10;
$background-dark-grey: $netural-20;
$background-icy-lake: $icy-lake-20;
$background-dark-icy-lake: $icy-lake-60;
$background-white: $netural-0;
$background-sahara: $sahara-10;
$background-golden-meadow: $golden-meadow-70;

$icon-light: $sahara-10;
$icon-dark: $netural-120;
$icon-green: $bliss-60;

$input-default: $netural-40;
$input-active: $bliss-80;
$input-error: $red-60;

$resume-blue: #006AFF;
$resume-black: #000000;
$resume-white: #ffffff;

// font weight
$font-weight-regulr: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// breakpoint
$desktop-breakpoint: 1280px;
$tablet-min-breakpoint: 768px;
$tablet-max-breakpoint: 1279px;
$mobile-breakpoint: 320px;

// grids
$desktop-column-width: 4.625rem;
$desktop-gutter-width: 1.5rem;
$desktop-column-count: 12;

// columns
$desktop-12-columns: ($desktop-column-width * 12)+ ($desktop-gutter-width * 11);
$desktop-10-columns: ($desktop-column-width * 10)+ ($desktop-gutter-width * 9); // video
$desktop-8-columns: ($desktop-column-width * 8)+ ($desktop-gutter-width * 7); // text
$desktop-6-columns: ($desktop-column-width * 6)+ ($desktop-gutter-width * 5); // form
$desktop-4-columns: ($desktop-column-width * 4)+ ($desktop-gutter-width * 3); // next video

// Spacing tokens
$spacing-4: to-rem(4);
$spacing-8: to-rem(8);
$spacing-12: to-rem(12);
$spacing-16: to-rem(16);
$spacing-18: to-rem(18);
$spacing-20: to-rem(20);
$spacing-24: to-rem(24);
$spacing-28: to-rem(28);
$spacing-32: to-rem(32);
$spacing-40: to-rem(40);
$spacing-48: to-rem(48);
$spacing-56: to-rem(56);
$spacing-60: to-rem(60);
$spacing-64: to-rem(64);
$spacing-72: to-rem(72);
$spacing-80: to-rem(80);
$spacing-92: to-rem(92);
$spacing-94: to-rem(94);
$spacing-96: to-rem(96);
$spacing-100: to-rem(100);
$spacing-104: to-rem(104);
$spacing-124: to-rem(124);
$spacing-126: to-rem(126);
$spacing-128: to-rem(128);
$spacing-154: to-rem(154);
$spacing-160: to-rem(160);
$spacing-196: to-rem(196);
$spacing-278: to-rem(278);