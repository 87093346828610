@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.card-tile{
    display: flex;
    flex-direction: column;
    border: 1px solid $divider;
    padding: $spacing-24;
    @include border-radius;
    gap: $spacing-16;
    
    @include desktop{
        gap: $spacing-24;
        padding: $spacing-32 $spacing-48;
    }

    &__upper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        text-align: left;
    }

    &__text{
        @include body-small;
    }

    &__image{
        width: 100%;
    }
}