@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.coming-soon{
    height: 100vh;
    
    @include desktop{
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        padding: 0;
    }

    &__inner-container{
        padding: $spacing-24;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;

        @include desktop{
            padding-top: to-rem(95);
        }

        @include tablet{
            padding: $spacing-32;
        }

        &--image{
            width: to-rem(320);
        }

        &--title{
            font-family: cutedrop;
            font-size: to-rem(56);
        }
    }

    &__content-container{
        background: $background-grey;
        display: flex;
        flex-direction: column;
        height: 100%;

        @include desktop{
            width: 100%;
            padding-left: $spacing-278;
        }
    }
}