@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.cv-template-link{
    display: flex;
    @include card-outline;
    transition: transform 0.3s ease;

    &--preview{
        width: to-rem(160);
        border-radius: to-rem(4);
    }

    @include desktop{
        &:hover{
            transform: translateY(to-rem(-6));
            box-shadow: 0px 16px 29.3px 0px rgba(0, 0, 0, 0.05);
        }
    }
}

.cv-template-placeholder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include card-outline-dash;

    &__container{
        width: to-rem(160);
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        align-items: center;

        &--icon{
            height: to-rem(40);
            color: $icon-green;
        }
    
        &--text{
            @include body-small;
            font-weight: 600; 
            text-align: center;   
        }
    }
}