@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.cv-template-link-group{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: $spacing-16;
    scroll-snap-type: x mandatory;
    scroll-padding-left: $spacing-24;  
    width: 100%;

    @include desktop{
        gap: $spacing-24;
        overflow: visible;
    }

    &::-webkit-scrollbar {
        display: none;  
    }

    -ms-overflow-style: none;   
    scrollbar-width: none;

    & > * {
        flex: 0 0 auto;
        scroll-snap-align: start;

        @include desktop{
            flex: initial;
            scroll-snap-align: unset;
            min-width: auto;
        }
    }
}