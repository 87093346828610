@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.input-field{
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;

    &__label{
        @include h4-font;
    }

    &__input{
        height: to-rem(44);
        border: 1px solid $input-default;
        border-radius: to-rem(4);
        padding: 0 $spacing-8;
        @include h4-font;
        box-sizing: border-box;
        width: 100%;
        
        &:focus {
            border: 2px solid $input-active;
            outline: none;  
        }

        &--error{
            border-color: $input-error;
        }
    }

    &__error{
        display: flex;
        flex-direction: row;
        gap: $spacing-4;
        color: $input-error;
        padding-top: $spacing-4;

        &--text{
            @include h5-font;
            font-weight: 500;
        }
    }
}