@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.footer{
    background: $background-forest;
    color: $text-light;

    &--fixed{
        position: fixed;
        width: -webkit-fill-available;
    }

    &--simplified-green{
        @include section-spacing;
        display: flex;
        align-items: center;
    }

    &--simplified-grey{
        @include section-spacing;
        display: flex;
        align-items: center;
        background: $netural-10;
        color: $text-dark;
    }

    &__section-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        @include section-spacing;
        gap: $spacing-24;
        
        @include desktop{
            max-width: $desktop-12-columns;
            gap: $spacing-48;
        }
    
        @include tablet{
            gap: $spacing-32;
        }
    }

    &__logo{
        height: to-rem(28);
    }

    &__link{
        text-decoration: none;
        @include body-medium;
        color: $text-light;

        &:hover{
            text-decoration: underline;
            opacity: 0.8;
        }

        &--icon{
            height: to-rem(24);
        }
    }

    &__top-panel{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        align-items: center;
        padding-bottom:$spacing-24;
        border-bottom: 1px solid rgba(254, 246, 233, 0.2);
        width: 100%;

        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            padding-bottom:$spacing-48;
        }

        @include tablet{
            flex-direction: row;
            justify-content: space-between;
            padding-bottom:$spacing-32;
        }
        
        &--links-wrapper{
            display: flex;
            flex-direction: row;
            gap: $spacing-24;
            align-items: center;
        }
    }
    
    &__middle-panel{

        @include tablet{
            flex-direction: row;
            padding-bottom:$spacing-32;
            justify-content: space-between;
        }

        &--section{
            display: flex;
            flex-direction: column;
            gap: $spacing-16;
        }

        &--title{
            @include body-large;
            font-weight: bold;
        }

        &--desktop{
            display: none;

            @include desktop{
                display: flex;
                flex-direction: row;
                padding-bottom:$spacing-48;
                border-bottom: 1px solid rgba(254, 246, 233, 0.2);
                justify-content: space-between;
                width: 100%;
            }
        }

        &--mobile{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $spacing-32;
            padding-bottom: $spacing-24;
            border-bottom: 1px solid rgba(254, 246, 233, 0.2);
            
            @include desktop{
                display: none;
            }
        }
    }

    &__legal{
        @include body-small;

        &--simplified{
            width: 100%;
            text-align: center;
        }

        &--simplified-grey{
            width: 100%;
            text-align: center;
            color: $text-light-grey;
        }

        &--default{
            width: 100%;
            text-align: left;
        }
    }

    &__mobile-accordion-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-16;

        &--accordion{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
    
        }

        &--accordion-content{
            display: flex;
            flex-direction: column;
            gap: $spacing-8;
        }

        &--accordion-animation{
            transition: transform 0.3s ease;
        }

        &--accordion-rotate{
            transform: rotate(180deg);
        }
    }

}