@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.side-navbar{
    padding: $spacing-24;
    background: $background-forest;

    @include desktop{
        padding: $spacing-32 $spacing-32 $spacing-32 $spacing-24;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: -webkit-fill-available;
        overflow: hidden;
        position: fixed;
        top: 0;
        z-index: 1;
        width: to-rem(222);
    }

    @include tablet{
        padding: $spacing-24 $spacing-32;
    }

    &__upper-panel{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include desktop{
            flex-direction: column;
            gap: $spacing-32;
            height: 100%;
            justify-content: normal;
        }

        &--link-wrapper{
            display: none;

            @include desktop{
                display: flex;
                flex-direction: column;
                gap: $spacing-16;
                padding-top: $spacing-24;
                border-top: 1px solid rgba(254, 246, 233, 0.20);
            }
        }

        &--logo{

            @include desktop{
                padding-left: $spacing-16;
                width: 100%;
            }
        }
    }

    &__lower-panel{
        display: none;

        @include desktop{
            display: flex;
            flex-direction: column;
            gap: $spacing-16;
            padding-top: $spacing-24;
            border-top: 1px solid rgba(254, 246, 233, 0.20);
        }
    }

    &__hamburger{
        @include desktop{
            display: none !important;
        }
    }

    &__link{
        @include body-large;
        text-decoration: none;
        color: $text-light;

        &:hover{
            text-decoration: underline;
        }

        &--dark{
            @include body-large;
            text-decoration: none;
            color: $text-dark;
        }
    }

    &__mobile-drawer{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 101;
        background: $background-icy-lake;
        transition: 0.5s;
        overflow-x: hidden;

        @include desktop{
            display: none;
        }
    }

    &__mobile-drawer-wrapper{
        display: flex;
        flex-direction: column;
        padding: $spacing-32; 
        height: -webkit-fill-available;
        gap: $spacing-16;
    }

    &__mobile-drawer-close-button{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__mobile-drawer-container{
        display: flex;
        flex-direction: column;
        gap: $spacing-124;
        height: 100%;
    }

    &__mobile-drawer-container-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        height: 100%;

        &--upper{
            display: flex;
            flex-direction: column;
            gap: $spacing-24;
        }

        &--lower{
            display: flex;
            flex-direction: column;
            gap: $spacing-24;
            border-top: 2px solid $divider-icy-lake;
            padding-top: $spacing-24;
        }
    }

    &__link{
        &--active{
            background: rgba(13, 13, 13, 0.10);
    
            @include desktop{
                background: rgba(13, 13, 13, 0.40);
            }
        }
    }
}