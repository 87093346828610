@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.dashboard-header{
    display: flex;
    flex-direction: column;
    padding: $spacing-24;
    gap: $spacing-24;
    background: $background-white;
    border-bottom: 1px solid $divider;

    @include desktop{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-32;
    }

    @include tablet{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-32;
    }

    &--simplified{
        display: flex;
        flex-direction: row;
        padding: $spacing-24;
        justify-content: space-between;
        background: $background-white;
        border-bottom: 1px solid $divider;
        z-index: 100;
    
        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: to-rem(46);
            position: fixed;
            width: -webkit-fill-available;
        }
    
        @include tablet{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: $spacing-32;
        }
    }


    &__text-container{
        display: flex;
        flex-direction: column;
        gap: $spacing-4;

        &--text{
            @include body-medium;
            color: $netural-80;
        }
    }

    &__left-panel{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__right-panel{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-16;
    }

    &__link-button{
        display: none;

        @include desktop{
            display: block;
        }

        @include tablet{
            display: block;
        }

        &--mobile{
            @include desktop{
                display: none;
            }
    
            @include tablet{
                display: none;
            }
        }

        &--arrow{

            @include desktop{
                transition: transform 0.2s ease;

                &:hover{
                    transform: translateX(to-rem(-4));
                }
            }
        }
    }

    &__title-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-8;
    }
}

.dashboard-sub-header{
    display: flex;
    flex-direction: column;
    padding: $spacing-20 $spacing-24;
    gap: $spacing-24;
    background: $background-white;
    border-bottom: 1px solid $divider;
    position: sticky;
    top: 0;
    width: -webkit-fill-available;
    box-sizing: border-box;
    z-index: 50;

    @include desktop{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-16 $spacing-32;
    }

    @include tablet{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-32;
    }

    &__text{
        @include body-large;
        font-weight: 600;
    }
}