@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.company-table-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-16;
    background: $background-white;
    border-bottom: 1px solid $divider;

    &:last-child {
        border-bottom: none;
    }

    &__text-container{
        display: flex;
        flex-direction: row;
        gap: $spacing-16;
        align-items: center;

        &--placeholder{
            width: to-rem(40);
            height: to-rem(40);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $background-dark-icy-lake;
            color: $text-light;
            @include h3-font;
            font-weight: 600;
            border-radius: to-rem(4);
        }
    }

    &__image-container{
        width: to-rem(40);
        height: to-rem(40);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-icy-lake;
        border-radius: to-rem(4);

        &--logo{
            width: to-rem(34);
        }
    }
}