@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.cv-template{ 
    background-color: $resume-black;
    color: $resume-white;
    margin: 0;
    padding: $spacing-40;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    overflow: hidden; 
    flex-wrap: wrap; 
    overflow-wrap: break-word;
    word-break: break-word;

    @include desktop{
        width: 816px;  
    }
    
    &__placeholder{
        font-size: to-rem(10);
        line-height: to-rem(16);
        font-family: 'Poppins', sans-serif;
        padding-top: $spacing-8;
        opacity: 0.6;
    }

    &__title{
        @include h4-font;
        font-weight: 600;
        padding-bottom: $spacing-8;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);;
    }

    &__body{
        font-size: to-rem(10);
        line-height: to-rem(16);
        font-family: 'Poppins', sans-serif;
        padding-top: $spacing-8;
    }

    &__intro-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__name-section{
        display: flex;
        flex-direction: column;
        height: 100%;

        &--name{
            @include body-large;
            font-weight: 700;
        }

        &--job{
            @include body-small;
            color: $resume-blue;
            font-weight: 600;
        }
    }

    &__contact-section{
        display: flex;
        flex-direction: column;
        @include h5-font;
    }

    &__contact-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-4;
    }

    &__history-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: $spacing-32;
    }

    &__education-section{
        flex: 1;
    }

    &__about-section{
        flex: 3;
    }

    &__experience-container{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
    }

    &__experience-section{
        display: flex;
        flex-direction: column;
      
    }

    &__company-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-32;

        &--company{
            font-weight: 700;
            color: $resume-blue;
        }
    }

    &__skill-section{
        display: flex;
        flex-direction: column;
    }

    &__skill-section-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;

        &--title{
            width: 100%;
        }
    }

    &__certification-section-wrapper{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: $spacing-32;

        &--title{
            color: $resume-blue;
            font-weight: 700;
            flex: 1;
        }

        &--info{
            flex: 3;
        }
    }
}

#cv-content {
    width: 816px;
    height: 1056px;
    background-color: $resume-black;
    color: $resume-white;
    margin: 0;
    padding: $spacing-40;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    overflow: hidden;
}

#cv-content > * {
    flex-grow: 0;
    flex-shrink: 0;
}