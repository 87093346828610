@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.edit-cv{
    @include desktop {
        display: flex;
        flex-direction: row;
        padding: 0;
        height: 100vh;  
        overflow: hidden; 
    }

    @include tablet{
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100vh;  
        overflow: hidden; 
    }

    &__list-container {
        display: flex;
        flex-direction: column;

        @include desktop {
            padding: $spacing-94 0 0 0;
            flex-direction: row;
            width: 100%;
            height: -webkit-fill-available;
        }

        @include tablet {
            flex-direction: row;
        }
    }

    &__list-container-left-panel {

        @include desktop {
            border-right: 1px solid $divider;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 100vh; 
        }

        @include tablet {
            border-right: 1px solid $divider;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 100vh; 
        }
    }

    &__list-container-right-panel {
        width: 100%;
    }

    &__list-container-right-panel-preview{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: $spacing-32;
    }

    &__content-container {
        background: $background-grey;

        @include desktop {
            width: 100%;
            padding-left: $spacing-278;
            height: -webkit-fill-available;
        }

        @include tablet {
            width: 100%;
            height: -webkit-fill-available;
        }
    }

    &__content-section {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        width: 100%;
    }

    &__form-container {
        width: -webkit-fill-available;
        display: flex;
        flex-direction: column;

        @include desktop{
            overflow-y: auto;
            overflow-x: hidden;
        }

        @include tablet{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &__form-container-wrapper{
        @include desktop{
            height: 100vh;
        }

        @include tablet{
            height: 100vh;
        }
    }

    &__list-container-form {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        border-bottom: 1px solid $divider;
        padding: $spacing-24;

        &:last-child{
            border-bottom: none;
        }

        @include desktop {
            margin: 0 $spacing-32;
            padding: $spacing-32 0;
        }

        @include tablet {
            margin: 0 $spacing-32;
            padding: $spacing-32 0;
        }

        &--side-by-side {
            display: flex;
            flex-direction: column;
            gap: $spacing-16;

            @include desktop{
                flex-direction: row;
            }

            @include tablet{
                flex-direction: row;
            }
        }

        &--inner{
            display: flex;
            flex-direction: column;
            gap: $spacing-24;
        }

        &--experience-wrapper{
            display: flex;
            flex-direction: column;
            gap: $spacing-24;
        }

        &--accordion-rotate{
            transform: rotate(180deg);
        }
    }

    &__sticky {
        padding: $spacing-16 $spacing-32;
        background-color: $background-white;
        border-top: 1px solid $divider;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 55;
        position: fixed;
        bottom: 0;
        width: -webkit-fill-available;
        gap: $spacing-16;

        @include desktop{
            position: sticky;  
            bottom: 0; 
            flex-direction: row;
            gap: 0;
        }

        @include tablet{
            position: sticky;  
            bottom: 0; 
            flex-direction: row;
            gap: 0;
        }
        
        &--side-by-side {
            display: flex;
            justify-content: center;
            gap: $spacing-16;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;

            @include desktop{
                flex-direction: row;
                width: auto;
            }

            @include tablet{
                flex-direction: row;
                width: auto;
            }
        }
    }

    &__save-text{
        @include body-small;
        color: $text-green;
        font-weight: 600;
    }

    &__cv-preview{
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }
}