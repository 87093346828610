@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.icon-button{
    width: to-rem(32);
    height: to-rem(32);
    display: flex;
    justify-content: center;
    align-items: center;

    &--xlarge{
        height: to-rem(24);
    };

    &--large{
        height: to-rem(20);
    };

    &--default{
        height: to-rem(16);
    };

    &--dark{
        color: $text-dark;
    };

    &--light{
        color: $text-light;
    };

    &--green{
        color: $text-green;
    };

    &--grey{
        color: $button-icon-grey-default;

        &:hover{
            color: $button-icon-grey-hover;
        }

        &:active{
            color: $button-icon-grey-active;
        }
    }
};
