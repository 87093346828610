@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.control-modal{
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    padding: $spacing-8;
    border-radius: to-rem(8);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    bottom: 100%;
    right: 0;
    background-color: $background-white;

    &__button{
        display: flex;
        flex-direction: row;
        padding: $spacing-4 $spacing-12 $spacing-4 $spacing-4;
        align-items: center;
        border-radius: to-rem(4);

        &--edit{
            &:hover{
                background-color: $background-success;
            }
        }

        &--delete{
            &:hover{
                background-color: $background-error;
            }
        }
    }

    &__text{
        @include h4-font;
        font-weight: 600;

        &--edit{
            color: $text-green;
        }

        &--delete{
            color: $text-red;
        }
    }

    &__icon{
        
        &--edit{
            color: $text-green;
        }

        &--delete{
            color: $text-red;
        }
    }
}