@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $background-forest;
    padding: $spacing-24;

    @include desktop{
        padding: $spacing-24 $spacing-32;
    }

    @include tablet{
        padding: $spacing-32 $spacing-80;
    }

    &--logo-only{
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $background-forest;
        padding: $spacing-24;
    
        @include desktop{
            padding: $spacing-24 $spacing-128;
        }
    
        @include tablet{
            padding: $spacing-32 $spacing-80;
        }
    }

    &__section-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include desktop{
            max-width: $desktop-12-columns;
        }
    }

    &__link-button{
        @include desktop{
            display: none !important;
        }
    }

    &__left-panel-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        @include desktop{
            flex-direction: row;
            gap: $spacing-32;
            align-items: center;
            justify-content: left;
        }
    }

    &__left-panel{
        display: none;

        @include desktop{
            display: flex;
            flex-direction: row;
            gap: $spacing-24;
        }
    }

    &__right-panel{
        display: none;

        @include desktop{
            display: flex;
            flex-direction: row;
            gap: $spacing-24;
            justify-content: center;
            align-items: center;
        }
    }

    &__link{
        @include body-large;
        text-decoration: none;
        color: $text-light;
        font-weight: 600;

        &:hover{
            opacity: 0.8;
        }

        &--dark{
            @include body-large;
            text-decoration: none;
            color: $text-dark;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: $spacing-24 $spacing-8;
            border-bottom: 1px solid $divider-icy-lake;
        }
    }

    &__mobile-drawer{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $background-icy-lake;
        transition: 0.5s;
        z-index: 99;

        @include desktop{
            display: none;
        }
    }

    &__mobile-drawer-wrapper{
        display: flex;
        flex-direction: column;
        padding: $spacing-32 $spacing-32 $spacing-80 $spacing-32; 
        justify-content: space-between;
    }

    &__mobile-drawer-close-button{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-bottom: $spacing-24;
        border-bottom: 1px solid $divider-icy-lake;
    }

    &__mobile-drawer-wrapper-upper{
        display: flex;
        flex-direction: column;
        gap: $spacing-124;
        height: 100%;
        justify-content: space-between;

        &--links{
            display: flex;
            flex-direction: column;
        }
    }
}

.open{
    transform: translateX(0);
}

.close{
    transform: translateX(100%);
}