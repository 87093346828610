@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.login{
    display: flex;
    flex-direction: column;
    height: 100vh;

    @include desktop{
        display: initial;
    }

    @include tablet{
        display: initial;
    }


    &__main-container{
        height: 100%;

        @include desktop{
            display: flex;
            flex-direction: row;
        }

        @include tablet{
            display: flex;
            flex-direction: row;
            height: 100vh;
        }
    }

    &__redyy-logo{
        height: to-rem(24);
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-4;

        @include desktop{
            gap: $spacing-8;
        }

        &--title{
            text-align: center;
        }
    }

    &__left-panel{
        display: flex;
        flex-direction: column;
        padding: $spacing-32 $spacing-32 $spacing-64 $spacing-32;
        gap: $spacing-24;

        @include desktop{
            padding: $spacing-128;
            width: 100%;
        }

        @include tablet{
            padding: $spacing-48 $spacing-32;
            width: 100%;
        }
    }

    &__field-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;

        &--form{
            display: flex;
            flex-direction: column;
            gap: $spacing-16;
        }
    }

    &__right-panel{
        display: none;
        background: $background-icy-lake;

        @include desktop{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: $spacing-56 $spacing-128;
        }

        @include tablet{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: $spacing-48 $spacing-32;
        }

        &--gif{
            width: 100%;
        }
    }

    &__login-text{
        @include h4-font;

        &--link{
            color: $text-link-default;
            text-decoration: underline;

            &:hover{
                color: $text-link-hover;
            }

            &:active{
                color: $text-link-active;
            }
        }
    }
}