@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

  .segment-control{
    --highlight-width: auto;
    --highlight-x-pos: 0;
    display: flex;

    &__controls {
        display: inline-flex;
        justify-content: space-between;
        background: #EFF0F3;
        border-radius: to-rem(8);
        width: 100%;
        padding: to-rem(8);
        overflow: hidden;
        position: relative;
        gap: $spacing-8;

        @include desktop{
            margin: auto;
            max-width: 500px;
        }

        &::before {
            content: "";
            background: $netural-0;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            border-radius: to-rem(4);
            width: var(--highlight-width);
            transform: translateX(var(--highlight-x-pos));
            position: absolute;
            top: to-rem(8);
            bottom: to-rem(8);
            left: 0;
            z-index: 0;
        }

        &--ready{
            &::before {
                transition: transform 0.3s ease, width 0.3s ease;
              }
          }
      }

      &__segment {
        width: 100%;
        position: relative;
        text-align: center;
        z-index: 1;

        @include desktop{
            min-width: to-rem(160);
        }

        &--active{
            color: $text-dark;
        }
      }

      &__input{
        opacity: 0;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        cursor: pointer;
        height: 100%;
      }

      &__label{
        cursor: pointer;
        display: block;
        font-weight: 700;
        padding: to-rem(12);
        transition: color 0.3s ease;
        @include body-medium;
        font-weight: 600;
      }
  }