@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.text-link{
    color: $text-link-default;
    @include body-small;
    font-weight: 600;
    text-decoration: none;

    &:hover{
        color: $text-link-hover;
        text-decoration: underline;
    }

    &:active{
        color: $text-link-active;
    }
}