@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.icon-container{
    display: flex;
    justify-content: center;
    align-items: center;

    &--container-xlarge{
        width: to-rem(32);
        height: to-rem(32);
    };

    &--xlarge{
        height: to-rem(24);
    };

    &--container-large{
        width: to-rem(32);
        height: to-rem(32);
    };

    &--large{
        height: to-rem(20);
    };

    &--container-default{
        width: to-rem(24);
        height: to-rem(24);
    };

    &--default{
        height: to-rem(16);
    };

    &--dark{
        color: $text-dark;
    };

    &--light{
        color: $text-light;
    };

    &--green{
        color: $icon-green
    };
};
