@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.notification{
    display: flex;
    flex-direction: row;
    padding: $spacing-12;
    border-radius: to-rem(4);
    gap: $spacing-8;
    align-items: flex-start;

    @include desktop{
        border-radius: to-rem(8);
        align-items: center;
        padding: $spacing-16;
    }

    &--error{
        background-color: $background-error;
    }

    &--info{
        background-color: $background-info;
    }

    &--default{
        background-color: $background-default;
    }

    &__text{
        @include h4-font;
        font-weight: 600;

        &--error{
            color: $text-error;
        }

        &--info{
            color: $text-info;
        }

        &--default{
            color: $text-default;
        }
    }

    &__icon{
        padding-top: to-rem(3);

        @include desktop{
            padding-top: 0;
        }
        
        &--error{
            color: $text-error;
        }

        &--info{
            color: $text-info;
        }

        &--default{
            color: $text-default;
        }
    }
}