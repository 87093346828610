@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.side-navber-link{
    display: flex;
    flex-direction: row;
    gap: $spacing-8;
    padding: $spacing-12 $spacing-16;
    color: $text-dark;
    border-radius: to-rem(4);
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;

    @include desktop{
        color: $text-light;
    }

    &__icon-container{
        width: to-rem(24);
        height: to-rem(24);
        display: flex;
        justify-content: center;
        align-items: center;

        &--icon{
            max-height: to-rem(16);
        }
    }

    &--text{
        @include body-medium;
        font-weight: 600;
        text-wrap: nowrap;
    }

    &:hover{
        @include desktop{
            background: rgba(13, 13, 13, 0.20);
        }
    }

    &:active{
        background: rgba(13, 13, 13, 0.10);

        @include desktop{
            background: rgba(13, 13, 13, 0.40);
        }
    }

    &.active{
        background: rgba(13, 13, 13, 0.10);

        @include desktop{
            background: rgba(13, 13, 13, 0.40);
        }
    }
}