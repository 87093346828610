@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.resume-history-table{
    @include card-outline;

    &__no-resumes{
        display: flex;
        justify-content: center;
        padding: $spacing-32;
        
        &--text{
            @include body-medium;
            color: $text-default;
        }
    }
}