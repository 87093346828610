@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.template{

    @include desktop{
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        padding: 0;
    }

    &__list-container{
        padding: $spacing-24;
        display: flex;
        flex-direction: column;
        gap: $spacing-32;
        height: 100%;

        @include desktop{
            gap: $spacing-64;
            padding: $spacing-126 $spacing-32 $spacing-32 $spacing-32;
            height: 100%;
        }

        @include tablet{
            padding: $spacing-32;
        }
    }

    &__content-container{
        background: $background-grey;

        @include desktop{
            width: 100%;
            padding-left: $spacing-278;
            display: flex;
            flex-direction: column;
        }

        @include tablet{
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100vh;
        }
    }

    &__content-section{
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        width: 100%;
    }
}