@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.price-card{
    padding: $spacing-24;
    text-align: left;
    @include border-radius;
    

    @include desktop{
        width: 100%;
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        text-align: left;
        padding-bottom: $spacing-24;
    }

    &__title-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-16;

        &--title{
            @include h1-font;
        }

        &--subtitle{
            @include h4-font;
        }

        &--text{
            @include h4-font;
        }
    }

    &__tageline{
        font-weight: 600;
        @include body-large;
    }
}

.grey{
    background: $background-grey;
    color: $text-dark;
    width: 50%;

    @include desktop{
        width: 100%;
    }
}

.green{
    background: $background-forest;
    color: $text-light;
    width: 50%;

    @include desktop{
        width: 100%;
    }
}