@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.table-title{
    display: flex;
    flex-direction: row;
    gap: $spacing-8;
    align-items: center;

    &--text{
        @include body-large;
        font-weight: 600;
    }
}