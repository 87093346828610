@use './partials/extends' as *;

@font-face{
    font-family: 'poppins';
    src:   url('../assets/fonts/poppins/Poppins-Regular.ttf') format('woff2'),
           url('../assets/fonts/poppins/Poppins-Regular.ttf') format('woff');
    font-style: normal;
    font-weight: $font-weight-regulr;
}

@font-face{
    font-family: 'poppins';
    src:   url('../assets/fonts/poppins/Poppins-Medium.ttf') format('woff2'),
           url('../assets/fonts/poppins/Poppins-Medium.ttf') format('woff');
    font-style: normal;
    font-weight: $font-weight-medium;
}

@font-face{
    font-family: 'poppins';
    src:   url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format('woff2'),
           url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format('woff');
    font-style: normal;
    font-weight: $font-weight-semibold;
}

@font-face{
    font-family: 'poppins';
    src:   url('../assets/fonts/poppins/Poppins-Bold.ttf') format('woff2'),
           url('../assets/fonts/poppins/Poppins-Bold.ttf') format('woff');
    font-style: normal;
    font-weight: $font-weight-bold;
}

@font-face {
    font-family: 'cutedrop';
    src: url('../assets/fonts/cutedrop/CuteDrop-Caps.otf') format('opentype');
    font-style: normal;
    font-weight: $font-weight-regulr;  
}

body{
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    cursor: pointer;
    appearance: none; 
}

h1{
    @include h1-font;
}

h2{
    @include h2-font;
}

h3{
    @include h3-font;
}

h4{
    @include h4-font;
}

p{
    margin: 0;
}

.body-large{
    @include body-large;
}

.body-medium{
    @include body-medium;
}

.body-small{
    @include body-small;
}

.tagline{
    @include label;
    color: $text-green;
}


.loading{
    display: flex;
    justify-content: center;  
    align-items: center;     
    height: 100vh;    
    font-weight: 600;
    flex-direction: row;
    gap: $spacing-16;
    animation: pulse 1.5s infinite;
}

.loader{
    @include body-medium;
    font-weight: 600;
    padding: $spacing-12;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);  
        opacity: 0.7;           
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}