@use "./functions" as *;
@use "./variables" as *;

// breakpoint
@mixin desktop{
    @media screen and (min-width: $desktop-breakpoint){
        @content;
    }
}

@mixin tablet{
    @media screen and (min-width:#{$tablet-min-breakpoint}) and (max-width: #{$tablet-max-breakpoint}) {
        @content;
    }
}

// typography 
@mixin h1-font {
    font-size: to-rem(24);
    line-height: to-rem(32);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop {
        font-size: to-rem(40);
        line-height: to-rem(56);
    }

    @include tablet{
        font-size: to-rem(40);
        line-height: to-rem(56);
    }
}

@mixin h2-font{
    font-size: to-rem(24);
    line-height: to-rem(32);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(32);
        line-height: to-rem(40);
    }

    @include tablet{
        font-size: to-rem(32);
        line-height: to-rem(40);
    }
}

@mixin h3-font{
    font-size: to-rem(18);
    line-height: to-rem(24);
    font-family: 'poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(24);
        line-height: to-rem(32);
    }

    @include tablet{
        font-size: to-rem(24);
        line-height: to-rem(32);
    }
}

@mixin h4-font{
    font-size: to-rem(14);
    line-height: to-rem(22);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(14);
        line-height: to-rem(22);
    }

    @include tablet{
        font-size: to-rem(14);
        line-height: to-rem(22);
    }
}

@mixin h5-font{
    font-size: to-rem(12);
    line-height: to-rem(18);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(12);
        line-height: to-rem(18);
    }

    @include tablet{
        font-size: to-rem(12);
        line-height: to-rem(18);
    }
}

@mixin body-large{
    font-size: to-rem(16);
    line-height: to-rem(24);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(18);
        line-height: to-rem(28);
    }

    @include tablet{
        font-size: to-rem(18);
        line-height: to-rem(28);
    }
}

@mixin body-medium{
    font-size: to-rem(14);
    line-height: to-rem(22);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(16);
        line-height: to-rem(22);
    }

    @include tablet{
        font-size: to-rem(16);
        line-height: to-rem(22);
    }
}

@mixin body-small{
    font-size: to-rem(12);
    line-height: to-rem(18);
    font-family: 'Poppins', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(14);
        line-height: to-rem(18);
    }

    @include tablet{
        font-size: to-rem(14);
        line-height: to-rem(18);
    }
}

@mixin label{
    font-size: to-rem(32);
    line-height: to-rem(34);
    font-family: 'cutedrop', sans-serif;
    margin: 0;

    @include desktop{
        font-size: to-rem(40);
        line-height: to-rem(40);
    }

    @include tablet{
        font-size: to-rem(40);
        line-height: to-rem(40);
    }
}

@mixin section-spacing{
    padding: $spacing-32;

    @include desktop{
        padding: $spacing-64 0;
    }

    @include tablet{
        padding: $spacing-32 $spacing-80;
    }
}

@mixin section-spacing-end{
    padding: $spacing-32 $spacing-32 $spacing-64 $spacing-32;

    @include desktop{
        padding: $spacing-64 0 $spacing-128 0;
    }

    @include tablet{
        padding: $spacing-32 $spacing-80 $spacing-64 $spacing-80;
    }
}

@mixin border-radius{
    border-radius: to-rem(8);

    @include desktop{
        border-radius: to-rem(16);
    }

    @include tablet(){
        border-radius: to-rem(12);
    }
}

@mixin card-outline{
    padding: $spacing-8;
    border: 1px solid $divider;
    border-radius: to-rem(6);
}

@mixin card-outline-dash{
    padding: $spacing-8;
    border: 1px dashed $divider;
    border-radius: to-rem(6);
}




