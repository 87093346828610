@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.resume-history-table-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-16;
    background: $background-white;
    border-bottom: 1px solid $divider;

    &:last-child {
        border-bottom: none;
    }

    &__left-wrapper{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: $spacing-4;
    }

    &__left-wrapper-text-container{
        display: flex;
        flex-direction: column;

        &--text{
            @include h5-font;
        }
    }

    &__right-wrapper{
        display: flex;
        flex-direction: row;
        gap: $spacing-8;

        &--modal{
            position: relative;
        }

        &--icon-button{
            border-radius: to-rem(4);

            &:hover{
                background-color: $netural-10;
            }

            &:active{
                background-color: $netural-20;
            }
        }
    }
}