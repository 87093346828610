@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.form-accordion-label{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include desktop{
        cursor: pointer;
    }

    &__title{
        @include body-medium;
        font-weight: 600;
    }

    &__wrapper{
        display: flex;
        align-items: center;
        gap: $spacing-8;
    }

    &__icon{
        height: to-rem(16);
        color: $text-dark;

        &--animation{
            transition: transform 0.3s ease;
        }
    }
}