@use '../../styles/partials/extends' as *;
@import url('../../styles/global.scss');

.textarea {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    width: 100%;

    &__label-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__label-button{
        width: to-rem(24);
        height: to-rem(24);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-white;
        border: 1px solid $button-icon-border;
        border-radius: to-rem(4);

        &:hover{
            background-color: $netural-10;
        }

        &:active{
            background-color: $netural-20;
        }

        &--icon{
            height: to-rem(12);
        }
    }

    &__label {
        @include h4-font;
    }

    &__input {
        height: to-rem(100); 
        border: 1px solid $input-default;
        border-radius: to-rem(4);
        padding: $spacing-8; 
        @include h4-font;
        box-sizing: border-box;
        resize: none;
        overflow: auto;

        &:focus {
            border: 2px solid $input-active;
            outline: none;
        }

        &--error {
            border-color: $input-error;
        }
    }

    &__error {
        display: flex;
        flex-direction: row;
        gap: $spacing-4;
        color: $input-error;
        padding-top: $spacing-4;

        &--text {
            @include h5-font;
            font-weight: 500;
        }
    }
}